var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"profile-nav-bg",staticStyle:{"background-image":"url('images/bg-profile.jpg')"}}),_c('a-card',{staticClass:"card-profile-head",attrs:{"bordered":false,"bodyStyle":{padding: 0,}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{staticClass:"col-info",attrs:{"span":24,"md":12}},[_c('div',{staticClass:"avatar-info"},[_c('h4',{staticClass:"font-semibold m-0"},[_vm._v(_vm._s(_vm.accountDetails.first_name)+" "+_vm._s(_vm.accountDetails.last_name))]),_c('p',[_vm._v("Checking Account")])])]),_c('a-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-end"},attrs:{"span":24,"md":12}},[(_vm.availableBalance)?_c('div',{staticClass:"avatar-info"},[_c('h4',{staticClass:"font-semibold m-0"},[_vm._v("$"+_vm._s(_vm.availableBalance))]),_c('p',[_vm._v("Available Balance")])]):_vm._e()])],1)]},proxy:true}])}),_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":8}}),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":8}},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
      'select_field',
      { rules: [{ required: true, message: 'Please select an option' }] } ]),expression:"[\n      'select_field',\n      { rules: [{ required: true, message: 'Please select an option' }] },\n    ]"}],attrs:{"placeholder":"Select a transfer type"},model:{value:(_vm.tType),callback:function ($$v) {_vm.tType=$$v},expression:"tType"}},[_c('a-select-option',{attrs:{"value":"Local"}},[_vm._v("Local")]),_c('a-select-option',{attrs:{"value":"International"}},[_vm._v("International")])],1)],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'account_name',
						{ rules: [{ required: true, message: 'Please input account name of beneficiary!', pattern: new RegExp(/[A-Za-z\s]+/) }] } ]),expression:"[\n\t\t\t\t\t\t'account_name',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input account name of beneficiary!', pattern: new RegExp(/[A-Za-z\\s]+/) }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Account Name"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'account_number',
						{ rules: [{ required: true, message: 'Please input account number of beneficiary!', pattern: new RegExp(/[0-9]{6}/)}] } ]),expression:"[\n\t\t\t\t\t\t'account_number',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input account number of beneficiary!', pattern: new RegExp(/[0-9]{6}/)}] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Account Number"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'bank',
						{ rules: [{ required: true, message: 'Please input beneficiary bank name'}] } ]),expression:"[\n\t\t\t\t\t\t'bank',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input beneficiary bank name'}] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Bank Name"}})],1),(_vm.tType == 'Local')?_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'route_number',
						{ rules: [{ required: true, message: 'Please input beneficiary routing number!', pattern: new RegExp(/[0-9A-Za-z]+/) }] } ]),expression:"[\n\t\t\t\t\t\t'route_number',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input beneficiary routing number!', pattern: new RegExp(/[0-9A-Za-z]+/) }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Routing Number"}})],1):_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'route_number',
						{ rules: [{ required: true, message: 'Please input beneficiary Swift Code or IBAN number!', pattern: new RegExp(/[0-9A-Za-z]+/) }] } ]),expression:"[\n\t\t\t\t\t\t'route_number',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input beneficiary Swift Code or IBAN number!', pattern: new RegExp(/[0-9A-Za-z]+/) }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Swift Code"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'address',
						{ rules: [{ required: true, message: 'Please input beneficiary address!' }] } ]),expression:"[\n\t\t\t\t\t\t'address',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input beneficiary address!' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Address"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{ rules: [{ required: true, message: 'Please input beneficiary email!', pattern: new RegExp(/^\S+@\S+$/) }] } ]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input beneficiary email!', pattern: new RegExp(/^\\S+@\\S+$/) }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Email"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'amount',
						{ rules: [{ required: true, message: 'Please input amount!', pattern: new RegExp(/0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*/)}] } ]),expression:"[\n\t\t\t\t\t\t'amount',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input amount!', pattern: new RegExp(/0*[1-9][0-9]*(\\.[0-9]+)?|0+\\.[0-9]*[1-9][0-9]*/)}] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Amount"}})],1),(_vm.otpStatus)?_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"OTP","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'otp',
						{ rules: [{ required: true, message: 'Please enter otp sent to your email' }] } ]),expression:"[\n\t\t\t\t\t\t'otp',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter otp sent to your email' }] },\n\t\t\t\t\t\t]"}],attrs:{"type":"password"}}),_c('a-alert',{attrs:{"message":"You are required to enter an OTP code sent to your email address. Enter it above to complete your transfer! ","type":"info"}})],1):_vm._e(),(_vm.otpStatus && !_vm.isLoading)?_c('div',[_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"},on:{"click":function($event){return _vm.submitOtp()}}},[_vm._v(" SUBMIT OTP $ SEND ")])],1)],1):(_vm.isLoading)?_c('div',{staticStyle:{"text-align":"center"}},[_c('a-spin',{attrs:{"size":"large"}})],1):_c('div',[_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" SEND MONEY ")])],1)],1),(_vm.loginFailed)?_c('a-alert',{staticStyle:{"margin-bottom":"15px"},attrs:{"message":_vm.failedMessage,"type":"error","show-icon":""}}):_vm._e(),_c('div',{staticStyle:{"text-align":"center"}},[(_vm.resendToken)?_c('a-icon',{attrs:{"type":"reload","theme":"outlined"},on:{"click":function($event){return _vm.getOtp()}}}):_vm._e(),_vm._v(" "),(_vm.resendToken)?_c('i',[_vm._v("Resend Token")]):_vm._e(),_vm._v(" "),(_vm.timer > 0 && _vm.otpStatus)?_c('i',[_vm._v("Resend Token in "+_vm._s(_vm.timer))]):_vm._e()],1),(_vm.successful)?_c('a-alert',{staticClass:"mb-10",attrs:{"message":"Transfer initiated successfully!","type":"success","show-icon":""}}):_vm._e(),(_vm.failed)?_c('a-alert',{staticClass:"mb-10",attrs:{"message":_vm.message,"type":"error","show-icon":""}}):_vm._e()],1)],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":8}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }